import React from 'react';

function App() {
  return (
    <div>
      <h1>James Waller</h1>
      <p>Analytics Engineering</p>
      <div className='linkholder'>
        <div className='linkedin'></div>
        <a href='https://www.linkedin.com/in/jcwaller1/'>LinkedIn</a>
      </div>
      
      <div><a href='James Waller CV.pdf' target='_blank' rel='noopener noreferrer' download='James Waller CV'>Click to download my CV</a></div>
      <div><a href='https://medium.com/@waller.jc'>Medium</a></div>
    </div>
  );
}

export default App;

